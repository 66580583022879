// React & Gatsby
import React from "react"
import Image from "react-bootstrap/Image"
// import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Fontawesome
import {faShoppingCart, faDownload} from "@fortawesome/free-solid-svg-icons";

// Custom
import Page from "../components/Layout/Page"
// import Hero from "../components/Hero"
import PillButton from "../components/PillButton";
import IconsGrid from "../components/Content/IconsGrid";

// Data & Styles


const ProductTemplate = ({ pageContext }) => {
    const { product } = pageContext;

    return (
        <Page className={`product ${product.frontmatter.slug}`}>
            <Container
                role={'main'}
                className={'main product-details mb-xs-2 px-xs-4'}
            >
                <Row className={'px-xs-2 mt-5 pt-5'}>
                    <Col className={'text-center mb-xs-4'} lg={8} sm={12}>
                        <Image
                            src={product.frontmatter.image}
                            alt={product.frontmatter.alt}
                            style={{width : "100%"}}
                        />
                    </Col>
                    <Col lg={4} sm={12} className={'mt-md-5'}>
                        <h1 className={'mb-4 mt-sm-4'}>{ product ? product.frontmatter.title : 'Title Placeholder'}</h1>
                        <p className={'mb-4 lead price'}>
                            <span>Price : {product.frontmatter.price === 0 ? 'Free' : `$ ${product.frontmatter.price}`}</span>
                        </p>
                        <h3>Features</h3>
                        <ul className={'features-list'}>
                            {   product.frontmatter.features ?
                                product.frontmatter.features.map((feature) => {
                                    return (<li>{feature}</li>)
                                }) : null
                            }
                        </ul>
                        <div className={'text-center text-lg-left mt-5'}>
                            <PillButton
                                text={
                                    product.frontmatter.price === 0
                                        ? 'Download Now'
                                        : `Buy Now - $ ${product.frontmatter.price}`
                                }
                                link={product.frontmatter.link}
                                variant={'primary'}
                                icon={product.frontmatter.price === 0 ? faDownload : faShoppingCart}
                                onClick={ () => { window.open(product.frontmatter.link) } }
                            />
                        </div>
                    </Col>
                </Row>
                <Row className={'mt-md-5'}>
                    <Col>
                        <div dangerouslySetInnerHTML={{ __html: product.html }} />
                    </Col>
                </Row>

                <IconsGrid slug={product.frontmatter.slug} />

            </Container>
        </Page>
    )
}

export default ProductTemplate
